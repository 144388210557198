
  import {Component, Ref, Vue} from "vue-property-decorator";
  import CustomFormDataTableDetail from "@/components/CustomForm/TableDetail/index.vue";
  import {AttachmentHostType, AuditFlowScope, CustomFormType} from "@/api/appService";
  import api from '@/api/index';
  import ExportWord from "@/components/DownloadWord/index.vue";
  import AuditButtons from "@/components/AuditButtons/index.vue";
  import {AuditBodyDto} from "@/utils/customDto";

  @Component({
    name:'CustomFormStandardDetail',
    components: {
      CustomFormDataTableDetail,
      ExportWord,
      AuditButtons
    }
  })
  export default class CustomFormStandardDetail extends Vue{
    @Ref() auditButtons!: AuditButtons;

    private dataId!:string;
    private formId!:string;


    private isProject = false;

    isReady = false;

    selector:string="detail-wrap"
    filename:string=""
    defaultName:string='合作方申请表-'
    styles:string=""

    auditParams: AuditBodyDto = {
      hostId: undefined,
      hostType: AuditFlowScope.PartnerApply,
      auditFlowId: undefined,
      auditStatus: undefined,
      audit: undefined
    }
    get hostType(){
      return CustomFormType.OrganizationUnit;
    }
    created(){
      this.dataId = this.$route.query.id as string;
      this.formId = this.$route.query.formId as string;

      this.isProject = true;

      if((this.dataId && this.hostType) || this.formId){
        this.isReady = true;
      } else {
        this.$message.error('缺少url参数 id 或 hostType，请检查!');
      }
    }

    fetchAuditData() {
      return api.organizationUnit.audit;
    }

    getData(){
        return api.organizationUnit.get({id:parseInt(this.dataId)}).then(res=>{
          console.log('1231232','res')
          this.filename = this.defaultName + res.displayName;
          this.auditParams!.hostId = res.id +''
          this.auditParams!.auditFlowId = res.auditFlowId;
          this.auditParams!.auditStatus = res.auditStatus;
          this.auditParams!.audit = res.audit;
          if(res.auditFlowId) {
            (this.auditButtons as any).fetchCanAudit()
          }
          return res
        });
    }

    changeAudit() {
      this.getData()
    }
    // 返回
    private cancel() {
      this.$router.back();
    }
  }
