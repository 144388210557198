
import api from "@/api/index"; //ABP API接口
import {Vue, Component} from "vue-property-decorator";
import ImportExcel from "@/components/ImportExcel/index.vue";
import {
  CustomFormType,
  DataDictionaryDto, DataDictionaryDtoPagedResultDto,
  OrganizationUnitCreateOrUpdateDto, OrganizationUnitDto,
  OrganizationUnitDtoPagedResultDto
} from "@/api/appService";
import PagedTableView from "@/components/PagedTableView/index.vue";
import AbSelect from "@/components/AbSelect/index.vue";
import moment from "moment";

@Component({
  name: "RoleList",
  components: {
    PagedTableView,
    AbSelect,
    ImportExcel
  }
})
export default class OrganizationList extends Vue {
  tableItems: OrganizationUnitDtoPagedResultDto[] = [];

  centerDialogVisible: boolean = false;

  queryForm = {
    displayName: "",
    contactEmailAddress: "",
    contactMobilePhone: "",
    contactName: "",
  };

  orgTypeList: DataDictionaryDto[] | undefined = []

  created() {
    this.fetchDataDictionary()
  }

  async fetchDataDictionary() {
    await api.dataDictionary.getDataDictionaryListByKey({key: 'OrganizationUnitType', maxResultCount: 1000})
      .then((res: DataDictionaryDtoPagedResultDto) => {
        this.orgTypeList = res.items
      })
  }

  // 获取表数据
  fetchData(params: any) {
    return api.organizationUnit.getAll(params);
  }

  formatOrganizationType(value: any, column: any, cellValue: any) {
    switch (cellValue) {
      case "Partner":
        return "合作方";

      case "School":
        return "学校";

      default:
        return "未知";
    }
  }

  async getCustomFormId(hostId: string | undefined, callBack: any) {
    await api.customFormService.getCustomFormId({
      hostType: CustomFormType.OrganizationUnit,
      hostId: hostId
    }).then(res => {
      callBack(res)
    })
  }

  // 新建
  handleCreate(id: number) {
    this.getCustomFormId(id.toString(), (formId: string) => {
      if (formId) {
        this.$router.push({
          name: "organizationCreate",
          query: {
            formId: formId,
            hostId: id.toString()
          }
        });
      } else {
        this.$confirm('暂无表单，是否前往创建?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          this.$router.push({
            name: "partnerCustomForm",
          });
        }).catch(() => {
          this.centerDialogVisible = false
        });
      }
    })
  }

  handleMenu(e: any) {
    switch (e.type) {
      case "detail": {
        this.jumpDetail(e.index, e.item);
        return;
      }
      case "edit": {
        this.handleEdit(e.index, e.item);
        return;
      }
      case "delete": {
        this.handleDelete(e.index, e.item);
        return;
      }
      case "research": {
        this.handleResearch(e.index, e.item);
        return;
      }
    }
  }

  // 编辑
  handleEdit(index: number, row: OrganizationUnitDto) {
    this.getCustomFormId(String(row!.organizationType), (formId: string) => {
      this.$router.push({
        name: "organizationEdit",
        params: {id: row.id!.toString()},
        query: {
          formId: formId,
        }
      });
    })
  }

  // 查看详情
  jumpDetail(index: number, row: OrganizationUnitDto) {
    this.getCustomFormId(String(row!.organizationType), (formId: string) => {
      this.$router.push({
        name: "orgDetail",
        query: {
          id: row.id!.toString(),
          formId: formId,
        }
      });
    })
  }

  // 删除
  async handleDelete(index: number, row: OrganizationUnitCreateOrUpdateDto) {
    this.$confirm("你确定删除吗?", "提示").then(async () => {
      await api.organizationUnit.delete({id: row.id}).then(() => {
        this.$message({
          type: "success",
          message: "删除成功!"
        });
      });
    });
  }

  // 查看尽职调查报告
  handleResearch(index: number, row: OrganizationUnitDto) {
    this.$router.push({
      name: 'dueDiligenceRecordList',
      query: {
        orgId: String(row.id)
      }
    })
  }
}
